.descripcionProducto{
    /* width: 100%; */
    min-width: 90%; 
    /* max-width: ; */
    /* background-color: green; */
    min-height: 200px;

    border-style: solid;
    border-width: thin;
    border-radius: 20px;
    padding: 10px;
    font-size: larger;
}


/* HeaderProducto */
.datosProducto{
    padding: 5px;
    width: 100%;
  }
  
  .headerProducto{
    min-width: 50%;
    max-width: 800px;
    /* background-color: aqua; */
  }
  
  .imagenytitulo{
    display: flex;
    flex-direction: row;
    /* background-color: grey; */
    padding-top: 10px;
    padding-bottom: 10px;
  }


.imagenMediana{
    width: 220px;
    height: 250px;
    object-fit: contain;
  }
  
  .imagenytitulo > h2{
    width: 100%;
    height: 10px;
  }
  
  @media screen and (max-width: 600px) {
    .imagenytitulo{
      display: flex;
      flex-direction: column;
      /* background-color: grey; */
      padding-top: 10px;
      padding-bottom: 10px;
      flex-wrap: wrap;
    }
  
    .descripcionProducto{
        display: flex;
        flex-direction: column;
        /* background-color: aqua; */
        padding-top: 10px;
        padding-bottom: 10px;
        flex-wrap: wrap;
        /* width: 100%; */
    }
    
  
    .imagenMediana{
      width: 96%;
      height: 250px;
    }
  }
  
  .buttonContactar{
    height: 38px;
    background-color: red;
    font-size: 1.6em;
    padding: 2.5px;
  }