.imagenChica{
    width: 100px;
    height: 100px;
}

.btnPanel{
    background-color: blue;
    color: white;
}

.producto{
    height: 120px;
    width: '80%';
    /* background-color: red; */
    border-radius: 5px;
    border-color: black;
    border-width: thin;
    border-style: solid;
    margin-bottom: 10px;
}