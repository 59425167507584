
/* listadoProductos */
.listadoProductos{
    flex:1;
    display: flex;
    justify-items: center;
    justify-self: center;
    justify-content: center;
    text-align: center;
    align-items: center;
    
  }
  
  .productoItem:hover{
    background-color: orange;
    top: -10px;
  }
  
  .productoItem{
    /* flex:1; */
    width: 45%;
    max-width: 275px;
    height: 230px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 40px;

    /* para la animacion */
    position: relative;
    top: 0;
    transition: top ease 0.5s;
  }
  
  .productoItem > .title{
    width: 100%;
    height: 38px;
    font-weight: bold;
  }
  
  .productoItem > .precio{
    width: 100%;
  }
  
  .productoItem > .img{
    object-fit: contain;
    width: 92%;
    max-height: 200px;
    margin: 2%;
    border-radius: 20px;
  }
  
  .borderInvisible{
    border-radius: 20px;
    padding: 5px;
  }