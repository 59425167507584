.vendedor{
    flex:1;
}

.imagenVendedorChica{
    width: 50px;
    height: 50px;
}

.apodoVendedor{
    font-size: 1.4em;
    font-weight: 400;
    height: 100%;
    padding: 10px;
}

