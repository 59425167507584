.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    z-index: 1;
}

/* UserMenu */
.userMenu{
    width: 40%;
    height: 60%;
    background-color: white;
    position: fixed;
    top: 5%;
    left: 30%;
    z-index: 9;
}

.btnMenu{
    background-color: blue;
    color: white;
}