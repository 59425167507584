@import './css/fonts.css';



.flexRow{
  display: flex;
  flex-direction: row;
}

.flexRowWrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


.flexWrap{
  flex-wrap: wrap;
}

.flexColumn{
  display: flex;
  flex-direction: column;
}

.flex1{
  flex:1;
}

.full{
  width: 100%;
  height: 100%;
  flex: 1;
}


.centeredVectical{
  display: flex;
  justify-items: center;
  justify-self: center;
  justify-content: center;
}

.centered{
  display: flex;
  justify-items: center;
  justify-self: center;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.topBar{
  background-color: lightskyblue;
  width: 100%;
  height: 40px;
}

.logoImage{
  width: 30px;
  height: 30px;
}

.topBar > div{
  max-width: 33%;
  min-width: 50px;
  max-width: 300px;
  align-self: center;
}

.leftAlign{
  text-align: left;
}

.rightAlign{
  text-align: right;
}

.leftPosition{
  position: absolute;
  left: 0px;
}

.rightPosition{
  position: absolute;
  right: 0px;
}
.icono{
  height: 24px;
  width: 24px;
  margin-top: 0px;
}

.rounded{
  border-radius: 50%;
  border-color: black;
  border-width: thick;
  border-style: solid;
}

.roundedThin{
  border-radius: 50%;
  border-color: black;
  border-width: thin;
  border-style: solid;
}


.simpleBorder{
  border-color: black;
  border-width: thick;
  border-style: solid;
}

.avatar{
  height: 192px;
  width: 192px;
}

.avatarChico{
  height: 48px;
  width: 48px;
}



.listaAmigos{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  padding-top: 10px;
  padding-bottom: 10px;
}

.thinking{
  background-color: lightgray;
  width: 95%;
  height: 64px;
  margin: 5px;
}

.inputThinking{
  width: 80%;
  height: 32px;
  margin: 8px;
}

.btnThinking{
  margin: 8px;
  height: 32px;
  background-color: lightgreen;
}

.btnNormal{
  margin: 8px;
  height: 32px;
  background-color: blue;
  color: white;
}

/*Tabs*/
.tabs{
  width: 100%;
  flex:1;
}

.oneTabSelected{
  width: '25%';
  color: white;
  background-color: black;
  height: 24px;
  /* flex: 0.25; */
  flex-grow: 1;
}

.oneTab{
  width: '25%';
  color: black;
  background-color: white;
  height: 24px;
  /* flex: 0.25; */
  flex-grow: 1;
}

/*twoColumns*/

.twoColumns{
  width: '100%';
  font-size: small;
}

.twoColumns > div{
  padding-bottom: 2px;
}

.twoColumns > div:hover{
  background-color: orange;
}

.twoColumns > * > div:hover{
  background-color: orange;
}

.twoColumns > * > .leftColumn{
  background-color: lightskyblue;
  flex: 0.33;
}

.twoColumns > * > .rightColumn{
  background-color: white;
  
  flex: 0.66;
}

/* ListadoPhotos */

.listadoPhotos{
  flex:1;
  width: 95%;
  min-height: 150px;
  height: 'auto';
  border-color: black;
  border-width: thin;
  border-style: solid; 
  margin-top: 5px;
  padding-top: 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.listadoPhotos > .photo{
  flex-grow: 1;
  width: 50%;
  height: auto;
  padding: 0px;
  margin-bottom: 2.5%;
}

.listadoPhotos > .photo > img{
  width: 90%;
}

.listadoPhotos > .photo:hover{
  background-color: orange;
}

/* Centered Message */
.centeredMessage{
  flex:1;
  display: flex;
  justify-items: center;
  justify-self: center;
  justify-content: center;
  /* background-color: red; */
}

.centeredMessage > * {
  width: 80%;
  
  align-items: center;
}

.fullScreenMessage{  
  display: flex;
  flex-direction: column;
  
  justify-items: center;
  justify-self: center;
  justify-content: center; 

}


.fullPage, .fullPage>*{
  width: 100vw;
  height: 100vh;
  margin: 0;
  margin-top: -60px;
  padding: 0;
}


/* varios */



